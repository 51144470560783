@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

:root {
  --color: #4973ff;
}

 body {
  font-size: 16px;
  font-family: 'Montserrat', sans-sherif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

//.hero {
//  //display: inline-block;
//  width: 100%;
//  height: 100%;
//  //position: relative;
//  //text-align: center;
//
//}
//.diagonal-hero-bg {
//  //position: absolute;
//padding:10%;
//  width: 100%;
//  //height: 100%;
//  background: #0e0e0e;
//  //z-index: -1;
//  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//}

// creates randomized star sizes
@function stars($n) {
  $value: '#{random(2000)}px #{random(2000)}px #767676';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #767676';
  }
  @return unquote($value);
}

// store random stars
$stars-small: stars(700);
$stars-medium: stars(200);
$stars-big: stars(100);


.stars {
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  //background: #0e0e0e;

}
.unit-bg{
  background-image: -webkit-linear-gradient(180deg,  #06284b 0%, #06284b 100%);

}

.stars > .small {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: starsAnimation 50s linear infinite;


  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
  }
}

.stars > .medium {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: starsAnimation 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
  }
}

.stars > .big {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  border-radius: 100%;
  animation: starsAnimation 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    border-radius: 100%;
  }
}

// swap from/to values to reverse animation
@keyframes starsAnimation {
  from {
    transform: translateY(-2000px);
  }
  to {
    transform: translateY(0px);
  }
}



$t: 1.5s;
$d: 0.08em;
$n: 3;
$e: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$front: white;
$back: lightblue;


.popout{
  font-family: Futura, sans-serif;
  font-weight: 900;
  font-size: 80px;
  padding-top: 80px;
  color:$front;
  //
  //@keyframes ani{
  //  0%{
  //    transform: translate3d(0,0,0);
  //    text-shadow: 0em 0em 0 $back;
  //    color: $front;
  //  }
  //  30%{
  //    transform: translate3d(0,0,0);
  //    text-shadow: 0em 0em 0 $back;
  //    color: $front;
  //  }
  //  70%{
  //    transform: translate3d($d,-$d,0);
  //    text-shadow: -$d $d $back;
  //    color: $front;
  //  }
  //  100%{
  //    transform: translate3d($d,-$d,0);
  //    text-shadow: -$d $d $back;
  //    color: $front;
  //  }
  //}
  span{
    position: relative;
    display: inline-block;
    animation: ani $t infinite alternate $e;
    @for $i from 1 through $n{
      &:nth-last-child(#{$i}n){ animation-delay: -$t*$i/$n/2;}
    }
  }

}




figure {
  margin: 0;
}



.article {
  text-align: right;
}

.featured-image-1 {
  grid-row: 2 / 3;
}

.number-1 {
  grid-row: 2 / 4;
}

.featured-image-2, .number-2 {
  grid-row: 4 / 5;

}

.number-1, .number-4 {
  align-self: end;
  color: $back;
  opacity: 50%;
  font-family: 'Fjalla One', sans-serif;
  font-size: 480px;
  line-height: 480px;
  grid-column: 1 / 2;
}


.number-3 {
  align-self: end;
  opacity: 50%;
  color:#BF40BF;
  font-family: 'Fjalla One', sans-serif;
  font-size: 300px;
  line-height: 300px;
  grid-column: 1 / 2;
}

.article-1, .article-4 {
  grid-column: 2 / 3;
}

.featured-image-2, .number-2 {
  align-self: end;
}

.number-2 {
  color: #eee;
  font-size: 200px;
  justify-self: end;
  line-height: 160px;
}

//.number-3 {
//  background: transparent;
//  border-radius: 130px;
//  color:#BF40BF;
//  opacity: 50%;
//  font-family: 'Fjalla One', sans-serif;
//  font-size: 100px;
//  height: 130px;
//  line-height: 130px;
//  //position: absolute;
//  grid-row: 6 / 7;
//  right: -10px;
//  text-align: center;
//  top: -20px;
//  width: 130px;
//}

.featured-image-3 img {
  height: 100%;
}

.number-4 {
  font-size: 320px;
  left: -20px;
  line-height: 320px;
  position: absolute;
}

@media (min-width: 600px) {

  .wrapper {
    grid-template-columns: repeat(2, 1fr 2fr);
    margin: 24px auto;
  }

  .header {
    grid-column: 3 / 5;
    width: 70%;
  }

  .featured-image-1 {
    grid-row: 1 / 3;
  }

  .article-1 {
    grid-row: 3 / 4;
  }

  .article-2 {
    grid-column: 4 / 5;
    grid-row: 4 / 5;
  }

  .featured-image-2, .number-2 {
    grid-column: 3 / 5;
    grid-row: 2 / 4;
  }

  .featured-image-3, {
    grid-row: 4 / 6;
  }

  .featured-image-4, .number-4 , .number-3 {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
  }

  .number-4, .number-3 {
    font-size: 320px;
    grid-row: 5 / 7;
  }

  .article-3, .article-4 {
    grid-row: 6 / 7;
  }

  .article-4 {
    grid-column: 4 / 5;
  }

}



.badge-clicked{

text-align: center;
  background-color: rgb(239, 247, 255);
  border-radius: 6px;
  color: #005ba7 !important;
}

.badge-unclicked{
  background-color: transparent;
  color: #5f30e2 !important;
  border: 1px solid #5f30e2;

}



.services{
  padding: 0;
  .icon{
    line-height: 1.3;
    position: relative;
    z-index: 0;
    margin: 0 auto;
    width: 100px;
    span{
      font-size:60px;
      //color: $black;
    }
  }
  .media-body{
    width: 100%;
    h3{
      font-size: 16px;
      font-weight: 600;
      //color: $black;
      letter-spacing: 2px;
    }
    color: rgba(0,0,0,.8);
    p{
      margin-bottom: 0;
    }
  }
  &.services-bg{
    //background: $primary;
    &.services-darken{
      //background: darken($primary,3%);

    }
    &.services-lighten{
      //background: darken($primary,2%);

    }
    .icon{
      line-height: 1.3;
      position: relative;
      z-index: 0;
      margin: 0 auto;
      width: 100px;
      span{
        font-size:60px;
        //color: $white;
      }
    }
    .media-body{
      width: 100%;
      h3{
        font-size: 20px;
        font-weight: 600;
        //color: $white;
      }
      color: rgba(255,255,255,.8);
      p{
        margin-bottom: 0;
      }
    }
  }
}












