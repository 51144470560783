

@font-face {
  font-family: "Flaticon";
  src: url("./font/Flaticon.eot");
  src: url("./font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./font/Flaticon.woff") format("woff"),
       url("./font/Flaticon.ttf") format("truetype"),
       url("./font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;


  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-vector:before { content: "\f100"; }
.flaticon-web-programming:before { content: "\f101"; }
.flaticon-layer:before { content: "\f102"; }
.flaticon-coding:before { content: "\f103"; }
.flaticon-zoom:before { content: "\f104"; }
